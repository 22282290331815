export default {
  modalTitle: {
    id: 'service_fee_modal_title',
    defaultMessage: 'Back Market quality fee',
  },
  modalTrigger: {
    id: 'checkout_summary_service_fee',
    defaultMessage: 'service fee',
  },
  alternativeTitle: {
    id: 'service_fee_modal_title_1',
    defaultMessage: 'Why is there a fee?',
  },
  alternativeDescription: {
    id: 'service_fee_modal_paragraph_1',
    defaultMessage:
      'Back Market is your bridge between a network of trusted sellers, so we can get you high quality devices at the most competitive prices.',
  },
  alternativeFirstSectionTitle: {
    id: 'service_fee_modal_title_2',
    defaultMessage: 'Thorough quality control',
  },
  alternativeFirstSectionLabel: {
    id: 'service_fee_modal_paragraph_2',
    defaultMessage:
      'Every item sold on Back Market goes through extensive quality screening by sellers. Back Market will also spot-check devices to make sure they meet the high standards of our Quality Charter. For example, every battery must be checked and tested to confirm that it reaches at least 85% of its initial charge capacity, meaning it has sufficient power for the long haul.',
  },
  alternativeSecondSectionTitle: {
    id: 'service_fee_modal_title_3',
    defaultMessage: 'Commercial Warranty',
  },
  alternativeSecondSectionLabel: {
    id: 'service_fee_modal_paragraph_3',
    defaultMessage:
      'Back Market also ensures sellers deliver on a 1-year commercial warranty, which entitles you to many benefits.',
  },
  alternativeThirdSectionTitle: {
    id: 'service_fee_modal_title_4',
    defaultMessage: 'Customer Care by Back Market',
  },
  alternativeThirdSectionLabel: {
    id: 'service_fee_modal_paragraph_4',
    defaultMessage:
      "Our friendly Customer Care agents are your lifeline should any issues arise. They'll connect with the seller and assist you in finding a solution as quickly as possible.",
  },
}
